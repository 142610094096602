.wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 0 120px;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1200px;
}

.inputWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(350px, 1fr) );
    grid-column-gap: 60px;
    @media screen and (max-width: 768px) {
        display: block;
    }
}

.input {
    /*width: px;*/
    /*margin-bottom: 60px !important;*/
    /*margin-right: 10px !important;*/
}

.inputDisabled {
    pointer-events: none;
}

/*.inputWrapper .input:last-child {*/
/*    margin-top: 10px;*/
/*}*/

@media screen and (max-width: 1742px) {
    .input {
        margin-bottom: 34px !important;
    }
}

@media screen and (max-width: 1230px) {
    .form {
        width: 93%;
    }
    .input {
        margin-right: 4px !important;
    }
}

@media screen and (max-width: 1024px) {
    .form {
        width: 80%;
    }
    .wrapper {
        margin: 20px 0;
    }
    .form {
        //padding: 25px 0;
    }
    .inputWrapper {
        flex-direction: column;
    }
    .input {
        width: 100%;
        margin-bottom: 20px !important;
    }
}

@media screen and (max-width: 768px)  {

    .wrapper {
        padding: 0 20px;
    }

    .form {
        width: 100%;
    }
}
