//
// about.scss
//

.skill-box {
  z-index: 3;
  position: relative;
  .progress {
    height: 8px;
    background: $gray-200;
    overflow: visible;
    position: relative;
  }
  .progress-bar {
    //position: relative;
    background-color: $primary;
    animation: animate-positive 2s;
    overflow: visible;
  }
  .progress-value {
    display: block;
    color: $dark;
    position: absolute;
    top: -31px;
    right: 0;
  }
}

.about-img {
  position: relative;
  margin-top: 0px;
  width: calc(50%);
  flex: none;
  @media (max-width: 991.98px) {
    width: 100%;
  }
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}
