.auctionBoxWrapper {
    width: 80px;
    text-align: center;
}

.auctionBox {
    cursor: pointer;
    background-color: #1c3634;
    color: #A8B9B4;
    padding: 8px;
    height: 50px;
    display: flex;
    flex-direction: column;
}

.auctionBox:hover {
    color: #A8B9B4;
    background-color: #152927;
}

.text {
    /*padding-left: 30px;*/
    /*padding-top: 10px;*/
    font-weight: bold;
    font-size: 16px;
    color: #A8B9B4;
    margin: 0;
}

.imgWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-grow: 1;*/
}

.img {
    /*width: 80%;*/
    /*height: 70%;*/
    width: 100%;
}

.active {
    color: #A8B9B4;
    background-color: #111c21;
}

@media screen and (min-width: 769px)  {
    .auctionBoxWrapper {
        position: absolute;
        overflow: hidden;
        left: 0;
        top: 52px;
        transform: translateY(-50%);
    }
    .active {
        border-right: 8px solid #D71929;
    }
    .auctionBox {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .auctionBox:first-child {
        margin-bottom: 4px;
    }
}

@media screen and (max-width: 768px)  {
    .auctionBoxWrapper {
        display: flex;
        width: 200px;
        margin: auto;
        border-radius: 8px;
        overflow: hidden;
    }
    .auctionBox {
        width: 50%;
        //height: 70px;
    }

    .img {
        padding: 8px;
    }
    .active {
        border-bottom: 4px solid #D71929;
    }
    .auctionBox:first-child {
        margin-right: 4px;
    }
}
