.languageWrapper {
    margin-left: auto;
    margin-right: 10px;
}

.toggleBtn {
    width: 100%;
    height: 70px;
    display: inline-block;
    padding: 0 !important;
    color: #A8B9B4 !important;
}

.btn {
    background-color: #1c3634 !important;
    color: #A8B9B4 !important;
}

.btn:hover {
    background-color: #152927 !important;
    color: #A8B9B4 !important;
}

.toggleBtn:hover {
    background-color: #000 !important;
    color: #A8B9B4 !important;
}

@media screen and (max-width: 1024px) {
    .languageWrapper {
        margin: 0;
    }
}
