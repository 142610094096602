//
// contact.scss
//

.custom-form {
  .form-label {
    font-weight: 600;
  }
  .form-control {
    font-size: 15px;
    padding:12px 16px;
    border-color: rgba($gray-600, 0.40);
    &:focus {
      outline: none;
      border-color: rgba($gray-600, 0.75);
      box-shadow: none;
    }
    &::placeholder {
      color: $gray-500;
    }
  }

  form {
    @media screen and (max-width: 768px) {
      .row:last-child {
        div {
          text-align: center !important;
        }
      }
    }
  }
}

.error-msg{
  padding: 13px 20px;
  color: $danger;
  border-color: $danger;
  background-color: $white;
}

.contact-info{
  .icon {
    position: relative;
    padding-bottom: 25px;
    &::before {
      z-index: 1;
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -7px;
      width: 45px;
      height: 45px;
      background-color: $primary; //rgba($primary, 0.25);
      margin: 0px auto;
      border-radius: 5px;
      transform: rotate(
        45deg
        );
    }

    i {
      color: $gold !important;
      z-index: 99;
      position: absolute;
      top: -5px;
      left: 0;
      right: 0;
    }
  }
}
