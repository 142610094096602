@import "../../assets/scss/variables";

.buttonWrapper {
    width: 95%;
    margin: 50px auto 40px;
    display: flex;
    justify-content: center;
}

.button {
    width: 200px;
    height: 40px;
    display: inline-block;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
}

.button:focus {
    outline: none;
}

.buttonWrapper .button:first-child {
    margin-right: 40px;
}

.link {
    text-decoration: none;
    color: #DEC899;
}

.link:hover {
    text-decoration: none;
    color: #ffffff;
}

@media screen and (max-width: 650px) {
    .buttonWrapper {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    .buttonWrapper .button {
        margin-right: 0 !important;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 400px) {
    .button {
        font-size: 14px;
    }
}
