.submitBtn {
  max-width: fit-content;
  margin: auto;
}

.loaderStyle {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
