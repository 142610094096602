@import "../../assets/scss/variables";

.infoWrapper {
    width: 95%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
}

.infoItem {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.infoText {
    width: calc(100% - 30px);
    padding-left: 10px;
    color: #000000;
}

.infoColorFirst {
    width: 8px;
    height: 8px;
    background-color: $primary;
    border-radius: 100%;
}

.infoColorSecond {
    width: 8px;
    height: 8px;
    background-color: $gold;
    border-radius: 100%;
}

.infoColorThird {
    width: 8px;
    height: 8px;
    background-color: $success;
    border-radius: 100%;
}

@media screen and (max-width: 1024px) {
    .infoWrapper {
        flex-direction: column;
    }
    .infoItem {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 650px) {
    .infoWrapper {
        width: 98%;
        padding-left: 5px;
    }
}

@media screen and (max-width: 400px) {
    .infoWrapper {
        font-size: 14px;
    }
}
