@import "../../assets/scss/variables";

.wrapper {
    flex: 1;
    display: flex;
    align-items: center;
}

.contacts {
    margin-left: 20%;
    display: flex;
    flex-direction: column;
}

.infoWrapper {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    color: #A8B9B4;
}

.contacts .infoWrapper:nth-child(4) .info {
    margin-left: 30px;
}

.icon {
    padding: 5px;
    font-size: 20px;
}

.serviceIcon {
    font-size: 40px;
    color: $primary;
}

.info {
    padding: 5px;
    margin: 0;
    font-size: 20px;
}

@media screen and (max-width: 1024px) {
    .wrapper {
        justify-content: center;
        margin: 40px 0;
    }
    .contacts {
        width: 80%;
        margin-left: 0;
        justify-content: center;
    }
    .contacts {
        padding: 25px 0;
    }
    .info {
        font-size: 15px;
    }
    .contacts .infoWrapper:nth-child(4) .info {
        margin-left: 30px;
    }
}
