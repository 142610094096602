//
// menu.scss
//

.navbar {
  transition: all 0.5s ease;
  padding: 12px 0px;

  .navbar_calculator_icon {
    display: none;
    color: rgba(0, 0, 0, 0.55);
    font-size: 27px;
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $dark;
        font-size: 15px;
        font-weight: 600;
        background-color: transparent;
        margin: 0 10px;
        padding: 6px 0;
        transition: all 0.3s ease;

        &:hover,
        &:active,
        &.active {
          color: $gold !important;
        }

        .lang_am & {
          font-size: 13px;
          @media screen and (max-width: 1200px) {
            font-size: 12px;
          }
        }
      }

      &.active {
        .nav-link {
          color: $gold !important;
        }
      }
    }
  }

  .navbar-toggler {
    padding: 0px;
    border: none;
    font-size: 24px;

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-brand {
    .logo-light {
      display: inline-block;
    }

    .logo-dark {
      display: none;
    }
  }
}

//nav-sticy
.nav-sticky {
  &.navbar {
    background: $primary;
    padding: 3px 0px;
    margin-top: 0px;
    box-shadow: 0px 3px 10px 0px rgba($gold, 0.08);

    .nav-item {
      .nav-link {
        color: $gold;

        &:hover,
        &.active,
        &:active {
          color: $gold;
        }
      }
    }
  }

  .navbar-brand {
    .logo-dark {
      display: inline-block;
    }

    .logo-light {
      display: none;
    }
  }
}

.navbar {
  &.navbar-light {
    background-color: $primary;

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $white;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .navbar {
    .navbar-nav {
      align-items: center;
      .nav-item {
        .nav-link {
          color: $white;
        }
      }
    }
  }

  .mobile_burger {
    display: none;
  }
  //nav-sticy
  .nav-sticky {
    &.navbar {
      background: $primary;

      .nav-item {
        .nav-link {
          color: $white;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .navbar {
    padding: 5px 0px;
    background-color: $white;

    .navbar_calculator_icon {
      display: block;
    }

    .navbar-nav {
      .nav-item, {
        margin: auto;
      }

      > div {
        margin: auto;

        button {
          span {
            justify-content: center;
          }
        }

      }
    }

    .container {
      > a, > button {
        width: 33%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
      }

      a:first-child {
        justify-content: left;
      }

      button {
        justify-content: right;
        i {
          width: 100%;
          text-align: right;
        }
      }
    }

    .navbar-brand {
      margin: 0;

      .logo-light {
        display: none;
      }

      .logo-dark {
        display: inline-block;
      }
    }

    i {
      color: $gold;
    }
  }
  .desktop_burger {
    display: none;
  }
}
