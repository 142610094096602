//
// process.scss
//

.process-box {
  .process-count {
    .process-number {
      background: $primary;
      color: $gold;
      width: 74px;
      height: 74px;
      line-height: 74px;
      border-radius: 50%;
      margin: 0 auto;
      transition: all 0.5s ease;
    }
  }
  .process-content {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 28px;
      height: 28px;
      background-color: $light;
      transform: rotate(45deg);
      left: 0;
      right: 0;
      margin: 0px auto;
      margin-top: -35px;
    }
  }
  &:hover {
    .process-count {
      .process-number {
        box-shadow: 0px 0px 0px 7px rgba($primary, 0.5);
      }
    }
  }
}

@media (min-width: 992px) {
  .process-border {
    &:before {
      content: "";
      border: 1px solid rgba($primary, 0.4%);
      position: absolute;
      width: 76%;
      left: 62%;
      top: 65px;
    }
  }
}

@media (min-width: 768px) {
  .process-box {
    .process-content {
      min-height: 250px;
    }
  }
}
