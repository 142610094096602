.iconBar {
    position: absolute;
    right: 0;
    top: 0;
    width: 70px;
    height: 100%;
    display: block;
    background-color: #1c3634;
}

.link {
    padding: 15px 0;
    display: block;
    font-size: 30px;
    text-align: center;
    color: #A8B9B4;
}

.link:hover {
    background-color: #152927;
    color: #A8B9B4;
}

@media screen and (max-width: 1024px) {
    .iconBar {
        display: none;
    }
}


