@import "../../assets/scss/variables";

.wrapper {
  //margin-top: 20px;

  @media screen and (max-width: 991px) {
    padding: 0 30px;
    margin: 0;
    span {
      font-size: 13px;
    }
  }
}

.calcTable {
  width: 95%;
  margin: 0 auto;
  padding-bottom: 75px;
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid $gold;
  @media screen and (max-width: 991px) {
    padding-bottom: 50px;
  }
  @media screen and (max-width: 480px) {
    padding-bottom: 90px;
  }

}

.dotSpan {
  width: 16px;
}

.logo {
  text-align: center;
  margin-bottom: 20px;
}

.calcItem {
  width: 47%;
}

.calcResultWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.calcCategory {
  width: 75%;
  height: 30px;
  margin-bottom: 10px;
  padding: 4px 10px;
  display: inline-block;
  background-color: #e6e7e8;
  margin-right: 1px;
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 8px;
  }
}

.calcValue {
  width: 20%;
  height: 30px;
  margin-bottom: 10px;
  padding: 4px;
  display: inline-block;
  text-align: center;
  background-color: #e6e7e8;
  font-weight: bold;
  min-width: fit-content;
}

.currency {
  height: 30px;
  width: 18px;
  padding: 4px 1px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: center;
}

.paymentColorFirst {
  &:before {
    background-color: $primary;
  }
}

.paymentColorSecond {
  &:before {
    background-color: $gold;
  }
}

.paymentColorThird {
  &:before {
    background-color: $success;
  }
}

.priceWrapper {
  width: 95%;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 991px) {
    margin: 10px auto;
  }
}

.total {
  width: calc(47% - 18px);
  height: 35px;
  padding: 4px 10px;
  font-weight: bold;
  background-color: #e6e7e8;
}

.priceContainer {
  width: 47%;
  display: flex;
  justify-content: space-between;
}

.priceItem {
  width: 45%;
  height: 35px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: fit-content;
  }
}

.price {
  height: 35px;
  width: calc(100% - 20px);
  margin-right: 2px;
  padding: 4px 10px;
  display: inline-block;
  font-weight: bold;
  background-color: #e6e7e8;
}

.amdCurrency, .usdCurrency {
  width: 18px;
  padding: 4px 1px;
  display: inline-block;
  font-weight: bold;
  text-align: center;
}

.metaText {
  transform: translateY(calc(-100% - 6px));
  text-align: center;
  color: $gold;
  font-family: Vesper, serif;
  font-size: 40px;
  line-height: 30px;
}

@media screen and (max-width: 1024px) {
  .calcTable {
    flex-direction: column;
  }
  .calcItem {
    width: 100%
  }
  .infoWrapper {
    flex-direction: column;
  }
  .infoItem {
    width: 100%;
  }
  .buttonWrapper {
    width: 100%;
    justify-content: space-between;
  }
  .buttonWrapper .button:first-child {
    margin-right: 0;
  }
  .button {
    width: 45%;
  }
  .priceWrapper {
    justify-content: flex-start;
  }
  .total {
    width: 37%;
    margin-right: 1%;
    padding: 4px 10px;
  }
  .priceContainer {
    width: 62%;
  }
  .priceContainer .priceItem:first-child {
    width: 59%;
    margin-right: 2%;
  }
  .priceItem {
    width: 39%;
  }
}

@media screen and (max-width: 650px) {
  .calcTable {
    width: 98%;
  }
  .priceWrapper {
    width: 98%;
  }
}

@media screen and (max-width: 520px) {
  .priceWrapper {
    flex-direction: column;
  }
  .priceContainer {
    width: 100%;
    flex-direction: column;
  }
  .total {
    width: calc(100% - 19px);
    margin-bottom: 20px;
    margin-right: 0;
  }
  .priceItem {
    width: 100%;
  }
  .price {
    width: calc(100% - 19px);
    margin-right: 1px;
  }
  .priceContainer .priceItem:first-child {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 400px) {
  .wrapper {
    font-size: 14px;
  }
  .price {
    padding: 7px 10px;
  }
  .total {
    padding: 7px 10px;
  }
  .amdCurrency, .usdCurrency {
    padding: 7px 1px;
  }
}
